
  import BaseLayout from './BaseLayout.vue'
  import { defineComponent } from 'vue'
  import { IonCol, IonRow, IonCard, IonCardContent } from '@ionic/vue'
  import { userService } from '@/services/userService'
  import { vedaService } from '@/services/vedaService'
  import { LOGGED_IN } from '@/events'

  // import router from '@/router'

  // await fetch("https://cddevapi.village.energy/sat/v1/dvi/veda?deviceSerialNumber={serialNumber}&debug=true&limit=10", {
  //     "credentials": "include",
  //     "headers": {
  //         "Content-Type": "application/json",
  //         "Authorization": "Bearer {accessToken}"
  //     },
  //     "method": "GET",
  //     "mode": "cors"
  // });
  export default defineComponent({
    name: 'Welcome',
    components: {
      BaseLayout,
      IonCol,
      IonRow,
      IonCardContent,
      IonCard,
    },
    data: () => {
      return {
        progress: false,
        device: {} as any,
        meters: []
      }
    },
    methods: {
      deviceDetails() {
        console.log('device')
        setTimeout(() => {
          if (!this.progress) {
            this.progress = true
            vedaService.vedaDetails().then(data => {
              console.log(data.data.data)
              console.log(data.data.data.device)
              this.device = data.data.data.device
              if (!('wifiSsid' in this.device) || !('wifiPassword' in this.device)) {
                userService.createWifiPopup()
              }
              this.device.serialNumber = vedaService.getVedaSN()
              vedaService.saveVedaDetails(data.data.data)
              if (data.data.data && data.data.data.meters) {
                this.meters = data.data.data.meters
              }
              this.progress = false
            }, (err) => {
              console.log(err)
              this.progress = false
            })
          }
        }, 1000)
      }
    },
    created() {
      console.log(userService.getEventEmitter())
      userService.getEventEmitter().on(LOGGED_IN, (isLoggedIn) => {
        console.log(isLoggedIn)
        this.deviceDetails()
      })
      userService.getAccessToken() ? this.deviceDetails() : ''
    }
  })
