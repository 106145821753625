<template>
  <base-layout page-title="Welcome">
    <template v-slot:main-content>
      <div id="container">
        <h2 class="ion-padding">
          You have connected to a Village Energy Data
          Acquisition Device (VEDA) with thefollowing details:
        </h2>
        <ion-grid>
          <ion-row class="ion-justify-content-start">
            <ion-col size="3">
              <div><ion-text color="primary"><h6>Serial Number</h6></ion-text></div>
            </ion-col>
            <ion-col size="9">
              <div><ion-text color="dark"><h6>{{ device.serialNumber }}</h6></ion-text></div>
            </ion-col>
          </ion-row>
          <ion-row class="ion-justify-content-start">
            <ion-col size="3">
              <div><ion-text color="primary"><h6>Device ID</h6></ion-text></div>
            </ion-col>
            <ion-col size="9">
              <div><ion-text color="dark"><h6>{{ device.deviceId }}</h6></ion-text></div>
            </ion-col>
          </ion-row>
          <ion-row class="ion-justify-content-start">
            <ion-col size="3">
              <div><ion-text color="primary"><h6>Microgrid Context</h6></ion-text></div>
            </ion-col>
            <ion-col size="9">
              <div><ion-text color="dark"><h6>{{ device.microgridContext }}</h6></ion-text></div>
            </ion-col>
          </ion-row>
          <ion-row class="ion-justify-content-start">
            <ion-col size="3">
              <div><ion-text color="primary"><h6>Microgrid ID</h6></ion-text></div>
            </ion-col>
            <ion-col size="9">
              <div><ion-text color="dark"><h6>{{ device.microgridId }}</h6></ion-text></div>
            </ion-col>
          </ion-row>
          <ion-row class="ion-justify-content-start">
            <ion-col size="3">
              <div><ion-text color="primary"><h6>Product ID</h6></ion-text></div>
            </ion-col>
            <ion-col size="9">
              <div><ion-text color="dark"><h6>{{ device.productId }}</h6></ion-text></div>
            </ion-col>
          </ion-row>
          <ion-row class="ion-justify-content-start">
            <ion-col size="3">
              <div><ion-text color="primary"><h6>Main PCB Version</h6></ion-text></div>
            </ion-col>
            <ion-col size="9">
              <div><ion-text color="dark"><h6>{{ device.mainPcbVer }}</h6></ion-text></div>
            </ion-col>
          </ion-row>
        </ion-grid>
        <!-- <ion-item>-->
        <!-- <b>Model:</b> [VEDA3]-->
        <!-- </ion-item>-->
        <!-- <ion-item>-->
        <!-- <b>Phase:</b> Single Phase three wire]-->
        <!-- </ion-item>-->
        <!-- <ion-item>-->
        <!-- <b>Rating:</b> [(10)/100A]-->
        <!-- </ion-item>-->
        <div>
          <ion-row>
            <ion-col>
              <h2 class="ion-padding">Meters</h2>
            </ion-col>
          </ion-row>
            <ion-card>
              <ion-card-content>
                <div v-for="(meter, index) in meters" :key="index">
                  <ion-grid>
                    <ion-row class="ion-justify-content-start">
                      <ion-col size="3">
                        <div><ion-text color="primary"><h5>Meter ID</h5></ion-text></div>
                      </ion-col>
                      <ion-col size="9">
                        <div><ion-text color="dark"><h5>{{ meter.meterId }}</h5></ion-text></div>
                      </ion-col>
                    </ion-row>
                    <ion-row class="ion-justify-content-start">
                      <ion-col size="3">
                        <div><ion-text color="primary"><h5>Meter Type</h5></ion-text></div>
                      </ion-col>
                      <ion-col size="9">
                        <div><ion-text color="dark"><h5>{{meter.meterType}}</h5></ion-text></div>
                      </ion-col>
                    </ion-row>
                    <ion-row class="ion-justify-content-start">
                      <ion-col size="3">
                        <div><ion-text color="primary"><h5>State</h5></ion-text></div>
                      </ion-col>
                      <ion-col size="9">
                        <div><ion-text color="dark"><h5>{{ meter.state }}</h5></ion-text></div>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </div>
              </ion-card-content>
            </ion-card>
          </div>
          <!--
            <p class="ion-padding">This VEDA requires Internet connectivity in order to utilise the web-based
              interface. The next screens will step you through connecting the VEDA to the Internet.</p>
            <div class="ion-padding">
              <ion-button router-link="/create-wifi-ap" expand="block" fill="outline">SETUP Wi-Fi ACCESS POINT</ion-button>
            </div>
          -->
        </div>
      </template>
    </base-layout>
</template>

<script lang="ts">
  import BaseLayout from './BaseLayout.vue'
  import { defineComponent } from 'vue'
  import { IonCol, IonRow, IonCard, IonCardContent } from '@ionic/vue'
  import { userService } from '@/services/userService'
  import { vedaService } from '@/services/vedaService'
  import { LOGGED_IN } from '@/events'

  // import router from '@/router'

  // await fetch("https://cddevapi.village.energy/sat/v1/dvi/veda?deviceSerialNumber={serialNumber}&debug=true&limit=10", {
  //     "credentials": "include",
  //     "headers": {
  //         "Content-Type": "application/json",
  //         "Authorization": "Bearer {accessToken}"
  //     },
  //     "method": "GET",
  //     "mode": "cors"
  // });
  export default defineComponent({
    name: 'Welcome',
    components: {
      BaseLayout,
      IonCol,
      IonRow,
      IonCardContent,
      IonCard,
    },
    data: () => {
      return {
        progress: false,
        device: {} as any,
        meters: []
      }
    },
    methods: {
      deviceDetails() {
        console.log('device')
        setTimeout(() => {
          if (!this.progress) {
            this.progress = true
            vedaService.vedaDetails().then(data => {
              console.log(data.data.data)
              console.log(data.data.data.device)
              this.device = data.data.data.device
              if (!('wifiSsid' in this.device) || !('wifiPassword' in this.device)) {
                userService.createWifiPopup()
              }
              this.device.serialNumber = vedaService.getVedaSN()
              vedaService.saveVedaDetails(data.data.data)
              if (data.data.data && data.data.data.meters) {
                this.meters = data.data.data.meters
              }
              this.progress = false
            }, (err) => {
              console.log(err)
              this.progress = false
            })
          }
        }, 1000)
      }
    },
    created() {
      console.log(userService.getEventEmitter())
      userService.getEventEmitter().on(LOGGED_IN, (isLoggedIn) => {
        console.log(isLoggedIn)
        this.deviceDetails()
      })
      userService.getAccessToken() ? this.deviceDetails() : ''
    }
  })
</script>
